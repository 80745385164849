import { makeVar } from '@apollo/client';
import type { ErrorResponse } from '@apollo/client/link/error';
import { ls } from '@drugfreesleep/utils/localStorage';

interface IAuthVar {
  user: any;
  jwt: string | null | undefined;
  refresh: string | null | undefined;
  remember: boolean | null | undefined;
  expired?: boolean;
}

const initialAuth: IAuthVar = ls.getJson('auth', { encrypted: true });

export const authVar = makeVar<IAuthVar>(initialAuth);

if (typeof window !== 'undefined') {
  (window as any).authVar = authVar;
}

export const errObjs: ErrorResponse[] = [];
// eslint-disable-next-line import/no-mutable-exports
let onJwtExpired: (expired: boolean) => void;

export const setAuth = (
  user: any,
  jwt?: string,
  refresh?: string,
  remember?: boolean | null | undefined
) => {
  ls.setJson('auth', { user, jwt, refresh, remember }, { encrypted: true });
  authVar({ user, jwt, refresh, remember });
  ls.removeItem('jwt_expired');
  if (jwt && errObjs.length > 0) {
    // TODO: this code is meant retry queries that failed after jwt and refresh expired
    // Disabled from move to new apollo.ts to avoid circular dependency
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // const client = getApolloClient();
    // errObjs.forEach((errObj) => {
    //   if (get(errObj, 'operation.query.definitions[0].operation') !== 'query') {
    //     return;
    //   }
    //   console.log(
    //     'retrying',
    //     errObj.operation.query,
    //     errObj.operation.variables
    //   );
    //   client
    //     .query({
    //       query: errObj.operation.query,
    //       variables: errObj.operation.variables,
    //     })
    //     .then((res) => {
    //       client.writeQuery({
    //         query: errObj.operation.query,
    //         variables: errObj.operation.variables,
    //         data: res.data,
    //       });
    //       console.log('retry success', res, client.cache);
    //     })
    //     .catch((err) => {
    //       console.log('retry error', err);
    //     });
    // });
    // errObjs.length = 0;
  }
};

export const jwtControl = {
  getDefaultOrg: () => {
    return ls.getJson('org');
  },
  setAuth,
  setDefaultOrg: (org: any) => {
    if (!org) {
      return;
    }
    ls.setJson('org', org);
  },
  getEmailVerified: () => {
    const auth = ls.getJson('auth', { encrypted: true });
    if (auth?.user?.userProfile?.email) {
      return true;
    }
    return false;
  },
  getMobileVerified: () => {
    const auth = ls.getJson('auth', { encrypted: true });
    if (auth?.user?.userProfile?.mobile) {
      return true;
    }
    return false;
  },
  logout: () => {
    ls.removeItem('auth');
    ls.removeItem('jwt_expired');
    authVar(undefined);
  },
  getAuth: () => {
    const auth = authVar();
    const org = ls.getJson('org');
    return { auth, org };
  },
  onJwtExpired: (setExpired: (expired: boolean) => void) => {
    onJwtExpired = setExpired;
  },
  isJwtExpired: () => {
    return ls.getBoolean('jwt_expired') as boolean;
  },
  setExpired: (expired: boolean) => {
    ls.setBoolean('jwt_expired', expired);
    if (onJwtExpired) {
      onJwtExpired(expired);
    }
    authVar({
      ...authVar(),
      expired,
    });
  },
  updateUserProfile: (userProfile: any) => {
    const auth = authVar();
    if (auth?.user) {
      auth.user.userProfile = userProfile;
      authVar(auth);
      ls.setJson('auth', auth, { encrypted: true });
    }
  },
};
