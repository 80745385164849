module.exports = {
  "props": {
    "apolloCache": {
      "Note:65": {
        "__typename": "Note",
        "id": 65,
        "userId": 1,
        "orgId": 1,
        "type": "note",
        "state": "PUBLISHED",
        "name": "Configuration for Weller.Care",
        "slug": "",
        "payload": {
          "md": "",
          "display": {
            "logo": "care",
            "name": "Weller Care"
          },
          "programs": {
            "54321": {
              "id": 6,
              "name": "The 5 Sense Method",
              "slug": "54321",
              "display": {
                "logo": "moon",
                "name": "The 5 Sense Method"
              },
              "payload": {
                "description": "One of the simplest and most reliable ways to interrupt acute anxiety."
              },
              "versionId": -1,
              "components": {
                "about": {
                  "id": 29
                },
                "homepage": {
                  "id": 62
                }
              },
              "targetOrigin": "https://weller.care/p/54321/",
              "organizationSlug": "weller-labs"
            }
          },
          "components": {
            "about": {
              "id": 29
            },
            "homepage": {
              "id": 73
            },
            "learn-more": {
              "id": 88
            }
          }
        },
        "meta": {},
        "isPublic": true,
        "createdAt": "2024-07-23T23:00:12.404092",
        "updatedAt": "2024-11-12T00:15:54.417681",
        "atVersion({\"input\":{}})": {
          "__typename": "Note",
          "userId": 1,
          "orgId": 1,
          "type": "note",
          "state": "PUBLISHED",
          "name": "Configuration for Weller.Care",
          "slug": "",
          "payload": {
            "md": "",
            "display": {
              "logo": "care",
              "name": "Weller Care"
            },
            "programs": {
              "54321": {
                "id": 6,
                "name": "The 5 Sense Method",
                "slug": "54321",
                "display": {
                  "logo": "moon",
                  "name": "The 5 Sense Method"
                },
                "payload": {
                  "description": "One of the simplest and most reliable ways to interrupt acute anxiety."
                },
                "versionId": -1,
                "components": {
                  "about": {
                    "id": 29
                  },
                  "homepage": {
                    "id": 62
                  }
                },
                "targetOrigin": "https://weller.care/p/54321/",
                "organizationSlug": "weller-labs"
              }
            },
            "components": {
              "about": {
                "id": 29
              },
              "homepage": {
                "id": 73
              },
              "learn-more": {
                "id": 88
              }
            }
          },
          "meta": {},
          "isPublic": true,
          "createdAt": "2024-07-23T23:00:12.404Z",
          "updatedAt": "2024-11-12T00:15:48.757Z"
        }
      },
      "ROOT_QUERY": {
        "__typename": "Query",
        "note({\"id\":65})": {
          "__ref": "Note:65"
        }
      }
    }
  }
};