import { isNaN } from 'lodash';

function getKey(): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 20; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const encrypt = (text: string) => {
  return `${getKey()}${btoa(text)}`;
};

const decrypt = (encryptedText: string) => {
  return atob(encryptedText.substring(20));
};

export interface ILocalStorageOptions {
  encrypted?: boolean;
}

// const PREFIX = AppConfig.code;
const PREFIX = 'CARE';
const stampKey = (key: string) => `${PREFIX}_${key}`;

export const setString = (
  key: string,
  value: string,
  options?: ILocalStorageOptions
) => {
  let v = value;
  if (typeof window !== 'undefined') {
    if (options?.encrypted) {
      v = encrypt(value);
    }
    localStorage.setItem(stampKey(key), v);
  }
};

export const setJson = (
  key: string,
  value: any,
  options?: ILocalStorageOptions
) => {
  if (value === undefined) {
    return;
  }
  setString(key, JSON.stringify(value), options);
};

export const setBoolean = (
  key: string,
  value: boolean,
  options?: ILocalStorageOptions
) => {
  setString(key, value ? 'true' : 'false', options);
};

export const getString = (
  key: string,
  options?: ILocalStorageOptions
): string | null => {
  if (typeof window !== 'undefined') {
    let v = localStorage.getItem(stampKey(key));
    if (v && options?.encrypted) {
      v = decrypt(v);
    }
    return v;
  }
  return null;
};

export const getJson = (
  key: string,
  options?: ILocalStorageOptions
): any | null => {
  const v = getString(key, options);
  if (v) {
    let parsed;
    try {
      parsed = JSON.parse(v);
    } catch (error) {
      console.error(error);
    }
    return parsed;
  }
  return null;
};

export const getBoolean = (
  key: string,
  options?: ILocalStorageOptions
): boolean | null => {
  const v = getString(key, options);
  if (v) {
    return v === 'true';
  }
  return null;
};

export const getNumber = (
  key: string,
  options?: ILocalStorageOptions
): number | null => {
  const v = getString(key, options);
  if (v !== null) {
    const parsed = parseFloat(v);
    return isNaN(parsed) ? null : parsed;
  }
  return null;
};

export const setNumber = (
  key: string,
  value: number,
  options?: ILocalStorageOptions
) => {
  setString(key, value.toString(), options);
};

export const removeItem = (key: string) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(stampKey(key));
  }
};

export const ls = {
  setString,
  setJson,
  getString,
  getJson,
  removeItem,
  setBoolean,
  getBoolean,
  getNumber,
  setNumber,
};
