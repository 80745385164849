import '../styles/global.css';

import FlowbiteContext from '@drugfreesleep/modules/flowbite/FlowbiteContext';
import { getApolloClient } from '@drugfreesleep/modules/graphql/apollo';
import { mergeWithCache } from '@drugfreesleep/modules/graphql/fetchNote';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

let config: any;
try {
  // eslint-disable-next-line global-require, import/extensions
  config = require('@/../data/config.js');
} catch (error) {
  config = null;
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  if (pageProps.apolloCache) {
    mergeWithCache(getApolloClient().cache, pageProps.apolloCache);
  } else if (config?.props?.apolloCache) {
    mergeWithCache(getApolloClient().cache, config.props.apolloCache);
  }

  // Listen to CMS messages
  const handleMessage = (event: MessageEvent) => {
    const { data } = event;
    if (data.type === 'navigateTo' && data.url) {
      router.push(data.url);
    }
  };

  useEffect(() => {
    // Register event listener for incoming messages
    window.addEventListener('message', handleMessage);

    // Send a message to the parent window (Studio) indicating that the Program is ready
    window.parent.postMessage({ type: 'programReady' }, '*');

    // Clean up event listeners
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    // Force 'light' theme
    localStorage.removeItem('theme');
  }, []);

  return (
    <FlowbiteContext>
      <Component {...pageProps} />
    </FlowbiteContext>
  );
};

export default MyApp;
