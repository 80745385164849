// @refresh reset

import type { CustomFlowbiteTheme } from 'flowbite-react';

export const customTheme: CustomFlowbiteTheme = {
  modal: {
    content: {
      base: 'relative w-full p-4 md:h-auto',
    },
  },
};
