export const AppConfig = {
  api_url: process.env.NEXT_PUBLIC_API_URL || 'https://api.lifeisachance.com',
  graphql_url:
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    'https://api.lifeisachance.com/graphql',
  site_name: 'Weller Care',
  title: 'Weller Care',
  description:
    'Weller Care hopes to support therapists and patients by increasing accessibility to treatment programs.',
  locale: 'en',
  configNoteId: process.env.NEXT_PUBLIC_CONFIG_NOTE_ID
    ? parseInt(process.env.NEXT_PUBLIC_CONFIG_NOTE_ID, 10)
    : 65,
  env: process.env.NEXT_PUBLIC_ENV,
};
