import type { ApolloCache } from '@apollo/client';
import { AppConfig } from '@drugfreesleep/config/AppConfig';
import type { WebsiteConfig } from '@drugfreesleep/config/useConfig';

import { getApolloClient } from './apollo';
import { NoteAtVersionDocument } from './generated-types';

export const fetchNoteForStaticProps = async (noteId: number) => {
  const client = getApolloClient();
  await client.query({
    query: NoteAtVersionDocument,
    variables: {
      id: noteId,
    },
  });

  return {
    props: {
      apolloCache: client.cache.extract(),
    },
  };
};

export const fetchStaticProps = async (componentSlugs: string[] = []) => {
  const client = getApolloClient();
  const configData = await client.query({
    query: NoteAtVersionDocument,
    variables: {
      id: AppConfig.configNoteId,
    },
  });
  const config = configData?.data?.note?.payload as WebsiteConfig;
  if (!config) {
    throw new Error(
      `No config found. Please set environment variable NEXT_PUBLIC_CONFIG_NOTE_ID id:'${AppConfig.configNoteId}' for ${AppConfig.api_url}. Did you set access to Locked or Public?`
    );
  }
  if (Object.keys(config.programs).length === 0) {
    await Promise.all(
      componentSlugs.map(async (slug) => {
        if (!config.components[slug].id) {
          throw new Error(
            `No note id found for slug ${slug}. Check config set by  NEXT_PUBLIC_CONFIG_NOTE_ID - ${AppConfig.configNoteId} at ${AppConfig.api_url}.`
          );
        }
        const noteData = await client.query({
          query: NoteAtVersionDocument,
          variables: {
            id: config.components[slug].id,
          },
        });
        const note = noteData?.data?.note;
        if (!note) {
          throw new Error(
            `No note found for id ${config.components[slug].id} slug ${slug} at ${AppConfig.api_url}. Did you set access to Locked or Public?`
          );
        }
      })
    );
  } else {
    await Promise.all(
      Object.keys(config.programs).map(async (pSlug) => {
        if (!config.programs[pSlug].id) {
          throw new Error(
            `No note id found for slug ${pSlug}. Check config set by  NEXT_PUBLIC_CONFIG_NOTE_ID - ${AppConfig.configNoteId} at ${AppConfig.api_url}.`
          );
        }
        if (componentSlugs.length > 0) {
          await Promise.all(
            componentSlugs.map(async (cSlug) => {
              if (!config.programs[pSlug].components[cSlug].id) {
                throw new Error(
                  `No note id found for slug ${cSlug}. Check config set by  NEXT_PUBLIC_CONFIG_NOTE_ID - ${AppConfig.configNoteId} at ${AppConfig.api_url}.`
                );
              }
              const cNoteData = await client.query({
                query: NoteAtVersionDocument,
                variables: {
                  id: config.programs[pSlug].components[cSlug].id,
                },
              });
              const cNote = cNoteData?.data?.note;
              if (!cNote) {
                throw new Error(
                  `No note found for id ${config.programs[pSlug].components[cSlug].id} slug ${cSlug} at ${AppConfig.api_url}. Did you set access to Locked or Public?`
                );
              }
            })
          );
        }
      })
    );
  }
  return {
    props: {
      apolloCache: client.cache.extract(),
    },
  };
};
interface InitialCache {
  [key: string]: any;
  ROOT_QUERY: {
    [key: string]: any;
  };
}
export const mergeWithCache = (
  existinCache: ApolloCache<any>,
  initialCache: InitialCache
) => {
  const existingData = existinCache.extract() as InitialCache;
  if (!existingData.ROOT_QUERY) {
    existinCache.restore(initialCache);
    return;
  }

  Object.keys(initialCache).forEach((key) => {
    if (key === 'ROOT_QUERY') {
      Object.keys(initialCache[key]).forEach((queryKey) => {
        if (!existingData.ROOT_QUERY[queryKey])
          existingData.ROOT_QUERY[queryKey] = initialCache[key][queryKey];
      });
      return;
    }
    if (!existingData[key]) existingData[key] = initialCache[key];
  });

  existinCache.restore(existingData);
};
